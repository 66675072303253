import React, { useState } from 'react';
import { IDrug } from '../interfaces/IDrug';
import { defaultAppState } from './DefaultAppState';
import { IPlan } from '../interfaces/IPlan';

// Creating the app context with the default state
export const AppContext = React.createContext(defaultAppState);

// Provider
export const AppProvider = ({ children }: any) => {
  // State
  const [selectedLetter, setSelectedLetter] = useState(defaultAppState.selectedLetter);
  const [selectedControl, setSelectedControl] = useState(defaultAppState.selectedControl);
  const [drugList, setDrugList] = useState(defaultAppState.drugList);
  const [drugResultList, setDrugResultList] = useState(defaultAppState.drugResultList);
  const [drugProfile, setDrugProfile] = useState(defaultAppState.drugProfile);
  const [therapeuticList, setTherapeuticList] = useState(defaultAppState.therapeuticList);
  const [therapeuticSubList, setTherapeuticSubList] = useState(defaultAppState.therapeuticList);
  const [cacheTime, setCacheTime] = useState(defaultAppState.cacheTime);
  const [filterTier, setFilterTier] = useState(defaultAppState.filterTier);
  const [filterAuth, setFilterAuth] = useState(defaultAppState.filterAuth);
  const [plansData, setPlansData] = useState(defaultAppState.plansData);
  const [planUrl, setPlanUrl] = useState(defaultAppState.planUrl);
  const [plan, setPlan] = useState(defaultAppState.plan);
  const [planState, setPlanState] = useState(defaultAppState.planState);

  // Custom Hooks
  const updateSelectedLetter = (letter: string) => setSelectedLetter(letter);
  const updateSelectedControl = (control: number) => setSelectedControl(control);
  const updateDrugList = (drugs: IDrug[]) => setDrugList(drugs);
  const updateDrugResultList = (drugs: IDrug[]) => setDrugResultList(drugs);
  const updateDrugProfile = (drug: IDrug) => setDrugProfile(drug);
  const updateTherapeuticList = (list: string[]) => setTherapeuticList(list);
  const updateTherapeuticSubList = (list: string[]) => setTherapeuticSubList(list);
  const updateCacheTime = (time: string) => setCacheTime(time);
  const updateFilterTier = (tier: number) => setFilterTier(tier);
  const updateFilterAuth = (auth: number) => setFilterAuth(auth);
  const updatePlansData = (plansData: IPlan[]) => setPlansData(plansData);
  const updatePlanUrl = (url: string) => setPlanUrl(url);
  const updatePlan = (plan: IPlan | null) => setPlan(plan);
  const updatePlanState = (planState: string) => setPlanState(planState);

  // Providing State and Update Functions to the App via the useState method
  return (
    <AppContext.Provider
      value={{
        selectedLetter,
        selectedControl,
        drugList,
        drugResultList,
        drugProfile,
        therapeuticList,
        therapeuticSubList,
        cacheTime,
        filterTier,
        filterAuth,
        plansData,
        planUrl,
        plan,
        planState,
        updateSelectedLetter,
        updateSelectedControl,
        updateDrugList,
        updateDrugResultList,
        updateDrugProfile,
        updateTherapeuticList,
        updateTherapeuticSubList,
        updateCacheTime,
        updateFilterTier,
        updateFilterAuth,
        updatePlansData,
        updatePlanUrl,
        updatePlan,
        updatePlanState
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
