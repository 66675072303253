import styles from './DownloadButton.module.scss';
import { DownloadIcon } from '../../icons/icons';
import axios from 'axios';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

const DownloadButton = (props: any) => {
  const { plan } = useContext(AppContext);

  return (
    <button className={`${styles.downloadButton} ${styles.icon} ${styles.baseline}`} onClick={() => handleData(plan?.formularyTier as number)}>
      <DownloadIcon />
      <span>Download</span>
    </button>
  );
};

const handleData = (tier: number) => {
  (async () => {
    const { data } = await axios.get(`/Drugs/${tier}/Print`);
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('id', 'temp-anchor');
    const fileName = 'formulary.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink.remove();
  })();
};

export default DownloadButton;
