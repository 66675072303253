import { FC } from 'react';

export const Announcement: FC = () => {
  return (
    <div className="announcement">
      <a href="https://simpra.com/providers/provider-documents/">
        PROVIDERS: 2024 Prior Authorization Summary lists are available. Prior authorization has been waived for most outpatient diagnostic procedures, wound care, and more. Download lists here to
        review. Call Provider Services with questions 1-844-637-4770.
      </a>
    </div>
  );
};
