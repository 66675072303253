import { FC } from 'react';
import HeaderMenuExternal from '../header-menu-external/HeaderMenuExternal';
import HeaderMenuMobile from '../header-menu-mobile';

const HeaderMenu: FC = () => {
  return (
    <div className="header-menu-container">
      <a href="https://simpra.com/">
        <img src={'https://simpra.com/wp-content/uploads/2023/06/Simpra-_Healthplan_-Logo.png'} alt="logo" style={{ maxWidth: '320px' }} />
      </a>
      <HeaderMenuMobile />
      <HeaderMenuExternal />
    </div>
  );
};

export default HeaderMenu;
