import axios from 'axios';
import { IAppContext } from '../context/DefaultAppState';
const getPlanData = async (appContext: IAppContext) => {
  try {
    const response = await axios.get('/Plans/Site/simpra.com');
    if (response && response.data.length) {
      const plan = response.data[0];
      document.documentElement.setAttribute('theme', plan.styleTheme);
      appContext.updatePlansData(response.data);
      appContext.updatePlanUrl(plan.planSite);
    }
  } catch (error: any) {
    console.log('error', error);
  }
};

const getCacheTime = async (appContext: IAppContext) => {
  try {
    const { data } = await axios.get(`/CacheTime`);
    appContext.updateCacheTime(data);
  } catch (err) {
    console.log(err);
  }
};

const initializeApp = async (appContext: IAppContext) => {
  await getPlanData(appContext);
  await getCacheTime(appContext);
  // await getDrugs(appContext);
};

export default initializeApp;
