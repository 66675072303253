import axios from 'axios';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { IAppContext } from '../../context/DefaultAppState';
import { Link } from 'react-router-dom';
import styles from './TherapeuticClassList.module.scss';
import { transformStringForURI } from '../../lib/util';

const TherapeuticClassList = () => {
  const appContext = useContext(AppContext);

  useEffect(() => initializeClassList(appContext), []);
  return (
    <>
      <h2 className={styles.letterTitle}>Therapeutic Class</h2>
      <div className="index-list">
        {appContext.therapeuticList.map((x, i) => (
          <div className={styles.listItem}>
            <Link key={`tl-${i}`} to={`/therapeutic/${appContext.plan?.formularyTier}/${transformStringForURI(x)}`}>
              {x}
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

const initializeClassList = (appContext: IAppContext) => {
  (async () => {
    const tier = appContext.plan?.formularyTier;
    const { data } = await axios.get(`/Drugs/${tier}/Category`);
    appContext.updateTherapeuticList(data);
  })();
};

export default TherapeuticClassList;
