import styles from './ResultRow.module.scss';
import { ArrowRight } from '../../../icons/icons';
import { IDrug } from '../../../interfaces/IDrug';
import React from 'react';
import { Link } from 'react-router-dom';
import { transformStringForURI } from '../../../lib/util';

interface IResultRowProps {
  tier: number,
  drug: IDrug;
  cssPosition: number;
}

const getWindowDimensions = (): { width: number; height: number } => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const ResultRow: React.FC<IResultRowProps> = ({ tier, drug, cssPosition }) => {
  return (
    <div className={`${styles.resultRow}${cssPosition % 2 !== 0 ? ' ' + styles.oddBackground : ''}`}>
      <div className={styles.rowColumn}>
        <Link to={`/profile/${tier}/${transformStringForURI(drug.drugProductId)}`} className={`${styles.icon} ${styles.baseline}`}>
          <span className={`${styles.primaryLabel}${drug.publicFormatCd ? ' ' + styles.italics : ''}`}>{drug.publicDrugName}</span>
        </Link>
      </div>
      <div className={styles.rowColumn}>
        <div className={styles.secondaryLabel}>Quantity Limits</div>
        <div className={styles.info}>{drug.qlPubRetailAmt1 ? `${drug.qlPubRetailAmt1} ${drug.qlPubRetailAmtUom1} / ${drug.qlPubRetailTime1} ${drug.qlPubRetailTimeUom1}` : 'None'}</div>
      </div>
      <div className={styles.rowColumn}>
        <div className={styles.secondaryLabel}>Tier</div>
        <div className={styles.info}>{drug.publicTier}</div>
      </div>
      <div className={styles.rowColumn}>
        <div className={styles.secondaryLabel}>Restrictions</div>
        <div className={styles.info}>Prior Authorization: {drug.priorAuthFlag ? 'Yes' : 'No'}</div>
        <div className={styles.info}>Step Therapy: {drug.stepTherapyFlag ? 'Yes' : 'No'}</div>
      </div>
      {getWindowDimensions().width > 834 ? (
        <div className={styles.rowColumn}>
          <Link to={`/profile/${tier}/${transformStringForURI(drug.drugProductId)}`} className={`${styles.icon} ${styles.baseline}`}>
            <span>Coverage details</span>
            <ArrowRight />
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default ResultRow;
