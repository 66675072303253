export const PrintIcon = () => (
  <>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/print</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/print" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons8-print" transform="translate(2.000000, 3.000000)" fill="var(--primary-color)" fillRule="nonzero">
          <path
            d="M5,0 C4.448,0 4,0.448 4,1 L4,3 C4,3.552 4.448,4 5,4 L15,4 C15.552,4 16,3.552 16,3 L16,1 C16,0.448 15.552,0 15,0 L5,0 Z M3,6 C1.35,6 0,7.35 0,9 L0,13 C0,14.105 0.895,15 2,15 L4,15 L4,17 C4,18.105 4.895,19 6,19 L14,19 C15.105,19 16,18.105 16,17 L16,15 L18,15 C19.105,15 20,14.105 20,13 L20,9 C20,7.35 18.65,6 17,6 L3,6 Z M17,8 C17.6,8 18,8.4 18,9 C18,9.6 17.6,10 17,10 C16.4,10 16,9.6 16,9 C16,8.4 16.4,8 17,8 Z M6.5,12 L13.5,12 C13.776,12 14,12.224 14,12.5 L14,16.5 C14,16.776 13.776,17 13.5,17 L6.5,17 C6.224,17 6,16.776 6,16.5 L6,12.5 C6,12.224 6.224,12 6.5,12 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  </>
);

export const DownloadIcon = () => (
  <>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/download-white</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/download-white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="icons8-download" transform="translate(1.000000, 2.000000)" fill="var(--primary-color)" fill-rule="nonzero">
          <path
            d="M10,0 C9.448,0 9,0.448 9,1 L9,9 L5.5,9 C5.22385763,9 5,9.22385763 5,9.5 C5,9.63260071 5.05271151,9.75976316 5.1464844,9.853516 C5.1564778,9.86372325 5.16690403,9.87349755 5.1777344,9.882812 L10.283203,14.697266 L10.316406,14.728516 C10.5015069,14.9025682 10.7459205,14.9996352 11,15 C11.2540795,14.9996352 11.4984931,14.9025682 11.683594,14.728516 L11.697266,14.716797 C11.7005507,14.7129156 11.7038058,14.7090091 11.707031,14.705078 L16.810547,9.892578 C16.8206742,9.88385851 16.8304477,9.87473652 16.839844,9.865234 L16.847656,9.859375 C16.8496255,9.85743822 16.8515789,9.85548515 16.853516,9.853516 C16.9472887,9.75976309 17,9.63260065 17,9.5 C17,9.22385763 16.7761424,9 16.5,9 L13,9 L13,1 C13,0.448 12.552,0 12,0 L11,0 L10,0 Z M2,18 C1.63936408,17.9948997 1.30391864,18.1843753 1.12211228,18.4958728 C0.940305922,18.8073704 0.940305922,19.1926296 1.12211228,19.5041272 C1.30391864,19.8156247 1.63936408,20.0051003 2,20 L20,20 C20.3606359,20.0051003 20.6960814,19.8156247 20.8778877,19.5041272 C21.0596941,19.1926296 21.0596941,18.8073704 20.8778877,18.4958728 C20.6960814,18.1843753 20.3606359,17.9948997 20,18 L2,18 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  </>
);

export const SearchIcon = () => (
  <>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/search-white</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/search-white" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons/search-grey" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) translate(3.000000, 3.000000)" fill="#FFFFFF" fillRule="nonzero">
          <path
            d="M7.2,0 C3.23420994,0 0,3.23420994 0,7.2 C0,11.1657897 3.23420994,14.4 7.2,14.4 C9.0215361,14.4 10.6845309,13.7133351 11.9548827,12.5912106 L12.6,13.2363279 L12.6,14.4 L16.2,18 L18,16.2 L14.4,12.6 L13.2363279,12.6 L12.5912106,11.9548827 C13.7133351,10.6845309 14.4,9.0215361 14.4,7.2 C14.4,3.23420994 11.1657897,0 7.2,0 Z M7.2,1.8 C10.1929977,1.8 12.6,4.20700221 12.6,7.2 C12.6,10.1929977 10.1929977,12.6 7.2,12.6 C4.20700221,12.6 1.8,10.1929977 1.8,7.2 C1.8,4.20700221 4.20700221,1.8 7.2,1.8 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  </>
);

export const ArrowLeft = () => (
  <>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/arrow-left-d</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/arrow-left-d" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="icons8-expand-arrow"
          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) translate(6.000000, 8.000000)"
          fill="var(--primary-color)"
          fillRule="nonzero"
        >
          <path
            d="M6,5.071 L2.179,1.25 C1.765,0.836 1.093,0.836 0.679,1.25 L0.679,1.25 C0.265,1.664 0.265,2.336 0.679,2.75 L5.293,7.364 C5.684,7.755 6.317,7.755 6.707,7.364 L11.321,2.75 C11.735,2.336 11.735,1.664 11.321,1.25 L11.321,1.25 C10.907,0.836 10.235,0.836 9.821,1.25 L6,5.071 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  </>
);

export const ArrowRight = () => (
  <>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/arrow-righ-d</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/arrow-righ-d" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="icons8-expand-arrow"
          transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) translate(6.000000, 8.000000)"
          fill="var(--primary-color)"
          fillRule="nonzero"
        >
          <path
            d="M6,5.071 L2.179,1.25 C1.765,0.836 1.093,0.836 0.679,1.25 L0.679,1.25 C0.265,1.664 0.265,2.336 0.679,2.75 L5.293,7.364 C5.684,7.755 6.317,7.755 6.707,7.364 L11.321,2.75 C11.735,2.336 11.735,1.664 11.321,1.25 L11.321,1.25 C10.907,0.836 10.235,0.836 9.821,1.25 L6,5.071 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  </>
);
