import { useContext, useState } from 'react';
import styles from './IndexHeaderControls.module.scss';
import { AppContext } from '../../context/AppContext';

const controlNames = ['Drug Name', 'Therapeutic Class'];

const IndexHeaderControls = () => {
  const appContext = useContext(AppContext);

  return (
    <>
      <div className={styles.controlContainer}>
        <div className={styles.controlButtonGroup}>
          {controlNames.map((control, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  appContext.updateSelectedControl(index);
                }}
                className={appContext.selectedControl === index ? styles.selectedControl : ''}
              >
                {control}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default IndexHeaderControls;
