import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { IDrug } from '../../interfaces/IDrug';
import { transformStringForURI } from '../../lib/util';
import { ResultsQueryType } from '../../pages/results';
import styles from './IndexList.module.scss';

const IndexList = () => {
  const { selectedLetter, drugList, plan } = useContext(AppContext);
  const tier = plan?.formularyTier;
  return (
    <>
      <h2 className={styles.letterTitle}>{selectedLetter.toUpperCase()}</h2>
      {tier ? <div className="index-list">{drugList.map((x, i) => mapDrug(x, i, selectedLetter, tier as number))}</div> : null}
    </>
  );
};

const mapDrug = (drug: IDrug, index: number, selectedLetter: string, tier: number): JSX.Element | null => {
  if (selectedLetter && drug.publicDrugName) {
    if (drug.publicDrugName[0].toUpperCase() === selectedLetter.toUpperCase()) {
      return (
        <p key={`${index}-drug`}>
          <Link className={`${styles.drugLink}${drug.publicFormatCd ? ' ' + styles.italics : ''}`} to={`/results/${tier}/${transformStringForURI(drug.publicDrugName)}/${ResultsQueryType.PublicName}`}>
            {drug.publicDrugName}
          </Link>
        </p>
      );
    }
  }
  return null;
};

export default IndexList;
