import FilterSelect from '../../components/FilterSelect/FilterSelect';
import PriorAuthFilterSelect from '../../components/PriorAuthFilterSelect/PriorAuthFilterSelect';
import ResultsTable from '../../components/ResultsTable/ResultsTable';
import styles from './Results.module.scss';
import { ArrowLeft } from '../../icons/icons';
import PrintButton from '../../components/PrintButton/PrintButton';
import DownloadButton from '../../components/DownloadButton/DownloadButton';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import axios from 'axios';
import { IAppContext } from '../../context/DefaultAppState';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { transformStringForURI } from '../../lib/util';

export enum ResultsQueryType {
  PublicName = 0,
  ProductName,
  Classification,
  Category
}

const ResultsPage = (props: any) => {
  const appContext = useContext(AppContext);
  const { tier, drugName, resultsQueryType } = props.match.params;
  useEffect(() => initializeResults(appContext, tier, drugName, resultsQueryType), []);

  return (
    <div>
      <div className={styles.topContainer}>
        <Link to={'/'} className={`${styles.icon} ${styles.baseline}`}>
          <ArrowLeft />
          <span>Prescription Drug List</span>
        </Link>
        <PrintButton />
        <DownloadButton />
      </div>
      <div className={styles.filtersContainer}>
        <PriorAuthFilterSelect />
        <FilterSelect />
      </div>
      <div className={styles.tableContainer}>
        <ResultsTable />
      </div>
    </div>
  );
};

const initializeResults = (appContext: IAppContext, tier: number, name: string, resultsQueryType: string) => {
  let url = '';
  const formularyTier = appContext.plan?.formularyTier ? appContext.plan?.formularyTier : tier;
  switch (parseInt(resultsQueryType)) {
    case ResultsQueryType.PublicName:
      url = `/Drugs/${formularyTier}/${transformStringForURI(name)}`;
      break;
    case ResultsQueryType.ProductName:
      url = `/Drugs/${formularyTier}/Product/${transformStringForURI(name)}`;
      break;
    case ResultsQueryType.Classification:
      url = `/Drugs/${formularyTier}/Class/${transformStringForURI(name)}`;
      break;
    case ResultsQueryType.Category:
      url = `/Drugs/${formularyTier}/Category/${transformStringForURI(name)}`;
      break;
  }

  (async () => {
    const { data } = await axios.get(url);
    appContext.updateDrugResultList(data);
  })();
};

export default ResultsPage;
