import { AppContext } from '../../context/AppContext';
import { useContext, useEffect, useState } from 'react';
import { IAppContext } from '../../context/DefaultAppState';
import DefaultButton from '../../components/DefaultButton/DefaultButton';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';

const PlanSelectionPage = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [selectedStateText, setSelectedStateText] = useState('');
  const [selectedPlanText, setSelectedPlanText] = useState('');
  const states = _.uniqBy(appContext.plansData, 'state').map((p) => p.state);
  const relevantPlans = appContext.plansData.filter((p) => p.state === selectedStateText);

  const handleContinue = () => {
    const path = window.location.href.split('/#/')[1];
    if (path) {
      history.push('/');
      history.push(`/${path}`);
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    //On load
    const numberOfStates = _.uniqBy(appContext.plansData, 'state').map((p) => p.state).length;
    if (numberOfStates === 1) {
      appContext.updatePlanState(states[0]);
      setSelectedStateText(states[0]);
    }
  }, [appContext]);

  return (
    <>
      <div>
        <h3 style={{ margin: '0' }}>Please Select a State...</h3>
        {states.map((state) => (
          <DefaultButton
            key={state}
            buttonText={state}
            customClick={() => {
              appContext.updatePlanState(selectedStateText);
              setSelectedStateText(state);
            }}
            selectedText={selectedStateText}
          />
        ))}
      </div>
      {selectedStateText ? (
        <div>
          <h3 style={{ margin: '0' }}>Please Select a Plan...</h3>
          {relevantPlans.map((plan) => (
            <DefaultButton key={plan.planName} buttonText={plan.planName} customClick={() => setSelectedPlanText(plan.planName)} selectedText={selectedPlanText} />
          ))}
        </div>
      ) : null}

      <DefaultButton
        buttonText="Continue to Formulary"
        customClick={() => {
          appContext.updatePlanState(selectedStateText);
          setSelectedPlanAndDrugs(appContext, selectedStateText, selectedPlanText);
          handleContinue();
        }}
        selectedText={''}
        shouldDisable={!selectedStateText || !selectedPlanText}
      />
    </>
  );
};

const setSelectedPlanAndDrugs = (appContext: IAppContext, stateText: string, planText: string) => {
  const plan = appContext.plansData.find((p) => stateText === p.state && planText === p.planName);
  if (plan) {
    appContext.updatePlan(plan);
  }
};

export default PlanSelectionPage;
