const transformStringForURI = (str: string): string => {
  const newStr = str.split('/').join('xSLASHx').split(' ').join('_').split('%').join('-PERCENT-');
  return newStr;
};

const decodeCustomUriString = (customString: string): string => {
  const decoded = customString.split('_').join(' ').split('-PERCENT-').join('%').split('xSLASHx').join('/');
  return decoded;
};

export { transformStringForURI, decodeCustomUriString };
