import styles from './PrintButton.module.scss';
import { PrintIcon } from '../../icons/icons';

const PrintButton = (props: any) => {
  return (
    <button className={`${styles.printButton} ${styles.icon} ${styles.baseline}`} onClick={() => window.print()}>
      <PrintIcon />
      <span>Print</span>
    </button>
  );
};

export default PrintButton;
