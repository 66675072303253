import styles from './Home.module.scss';
import IndexHeaderControls from '../../components/IndexHeaderControls/IndexHeaderControls';
import SearchBar from '../../components/SearchBar/SearchBar';
import IndexLetterButtons from '../../components/IndexLetterButtons/IndexLetterButtons';
import IndexList from '../../components/IndexList/IndexList';
import PrintButton from '../../components/PrintButton/PrintButton';
// import DownloadButton from '../../components/DownloadButton/DownloadButton';
import TherapeuticClassList from '../../components/TherapeuticClassList/TherapeuticClassList';
import { AppContext } from '../../context/AppContext';
import { useContext, useEffect } from 'react';
import { IAppContext } from '../../context/DefaultAppState';
import axios from 'axios';

const HomePage = (props: any) => {
  const appContext = useContext(AppContext);
  useEffect(() => resetHome(appContext), []);

  return (
    <>
      <div className={styles.topContainer}>
        <IndexHeaderControls />
        <PrintButton />
        {/* <DownloadButton /> */}
      </div>
      <hr />
      <div>
        {appContext.selectedControl === 0 ? (
          <div>
            <div>
              <SearchBar />
            </div>
            <hr />
            <div className="index-container">
              <IndexLetterButtons />
              <IndexList />
            </div>
          </div>
        ) : (
          <div>
            <TherapeuticClassList />
          </div>
        )}
      </div>
    </>
  );
};

const resetHome = (appContext: IAppContext) => {
  (async () => {
    try {
      const tier = appContext.plan?.formularyTier;
      const { data } = await axios.get(`/Drugsearch/${tier}`);
      appContext.updateDrugList(data);
    } catch (err) {
      console.log(err);
    }
  })();
};

export default HomePage;
