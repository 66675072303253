import './styles/styles.scss';
import { useContext, useEffect } from 'react';
import { Switch, Route, HashRouter, useHistory } from 'react-router-dom';
import ProfilePage from './pages/profile';
import ResultsPage from './pages/results';
import HomePage from './pages/home';
import { IAppContext } from './context/DefaultAppState';
import { AppContext } from './context/AppContext';
import initializeApp from './lib/initialization';
import axios from 'axios';
import { config } from 'dotenv';
import { NotFound } from './pages/notFound';
import TherapeuticSubClassList from './components/TherapeuticSubClassList/TherapeuticSubClassList';
import PlanSelectionPage from './pages/planSelection';
import HeaderMenu from './components/header-menu/HeaderMenu';
import { Announcement } from './components/Announcement';

config({
  path: `.env.${process.env.NODE_ENV}`
});

//simpra.com => REACT_APP_API_PROD_BASE_URL
//ipd.simpra.com => REACT_APP_API_PROD_BASE_URL
//formulary.simpra.com => REACT_APP_API_PROD_BASE_URL

//https://ipd-dev.logics.cc/#/ => REACT_APP_API_DEV_BASE_URL
//https://formulary-dev.logics.cc/#/ => REACT_APP_API_DEV_BASE_URL
const baseUIUrl = window.location.host;
var baseURL = process.env.REACT_APP_API_DEV_BASE_URL;
switch (baseUIUrl) {
  case 'simpra.com':
  case 'ipd.simpra.com':
  case 'formulary.simpra.com':
    baseURL = process.env.REACT_APP_API_PROD_BASE_URL;
    break;
  default:
    break;
}
axios.defaults.baseURL = baseURL;

const App = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  useEffect(() => initialize(appContext), []);

  return (
    <>
      <HeaderMenu />
      <Announcement />
      <div className="app-padding">
        <div className="header-container-app">
          <h1>Prescription Drug List</h1>
          <p>
            This online directory is the list of prescription drugs covered under the Part D benefit for this plan. It is known as the Formulary or the “Drug List” for short. The drugs on this list
            are selected by the plan with the help of a team of doctors and pharmacists. This list must meet the requirements set by Medicare. Medicare has approved this Drug List. The Drug List also
            lists any rules that restrict coverage for your drug(s).
          </p>
          {appContext.plan && (
            <ul>
            <li>
              For more information on the Drug List, read{' '}
              <a href={appContext.plan.formularyDisclaimerLink2} target="_blank" rel="noreferrer">
                2024 Formulary Introduction
              </a>
            </li>
            <li>
              To download all or part of the Drug List (100+ page PDF), click here:{' '}
              <a href={baseURL + 'drugs/' + appContext.plan.formularyTier + '/print/' + appContext.plan.id} target="_blank" rel="noreferrer">
                2024 {appContext.plan.planName} Formulary
              </a>
            </li>
            <li>
              To print a copy of your current page view, click the{' '}
              <a href="#" onClick={() => window.print()}>
                “Print”
              </a>{' '}
              icon below
            </li>
            <li>
              To go to a different plan, click here:{' '}
              <a
                onClick={() => {
                  appContext.updatePlan(null);
                  history.push('/');
                }}
              >
                <span> Select a Different Plan</span>
              </a>{' '}
            </li>
          </ul>
          )}
        </div>
        <div className="body-container">
          <HashRouter>
            <Switch>
              {appContext.plan ? (
                <>
                  <Route path="/plan-selection" exact component={PlanSelectionPage} />
                  <Route path="/profile/:tier/:drugId" component={ProfilePage} />
                  <Route path="/results/:tier/:drugName/:resultsQueryType" component={ResultsPage} />
                  <Route path="/therapeutic/:tier/:therapeuticCategory" component={TherapeuticSubClassList} />
                  <Route path="/" exact component={HomePage} />
                </>
              ) : null}
              {!appContext.plan || !appContext.planState ? <Route path="/" component={PlanSelectionPage} /> : null}
              <Route component={NotFound} />
            </Switch>
          </HashRouter>
        </div>
      </div>
    </>
  );
};

const initialize = (appContext: IAppContext) => {
  (async () => {
    await initializeApp(appContext);
  })();
};

export default App;
