import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import styles from './FilterSelect.module.scss';

const FilterSelect = () => {
  const appContext = useContext(AppContext);

  return (
    <>
      <select className={styles.select} onChange={(e) => appContext.updateFilterTier(parseInt(e.target.value))}>
        <option value={0}>Tier Filter</option>
        <option value={1}>Tier 1</option>
        <option value={2}>Tier 2</option>
        <option value={3}>Tier 3</option>
        <option value={4}>Tier 4</option>
        <option value={5}>Tier 5</option>
      </select>
    </>
  );
};

export default FilterSelect;
