import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SearchBar.module.scss';
import moment from 'moment';
import * as _ from 'lodash';
import { SearchIcon } from '../../icons/icons';
import { AppContext } from '../../context/AppContext';
import { IDrug } from '../../interfaces/IDrug';
import { transformStringForURI } from '../../lib/util';
import { ResultsQueryType } from '../../pages/results';

const SearchBar = () => {
  const { cacheTime, drugList, plan } = useContext(AppContext);
  const [relevantOptions, setRelevantOptions] = useState<IDrug[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const history = useHistory();

  return (
    <>
      <div className={styles.searchContainer}>
        <p className={styles.slabHeader}>Search list of FDA-approved drugs and coverage for each by entering a drug name below.</p>
        <p style={{ fontWeight: 'bold' }}>Last Updated: {moment.utc(cacheTime).format('MMMM, YYYY')}</p>
        <p style={{ fontWeight: 'bold' }}>Drug Name</p>
        <div className={styles.barContainer}>
          <input
            list="drugs"
            className={styles.searchBar}
            onChange={(e) => {
              handleInputChange(e, drugList, setRelevantOptions);
              setSearchValue(e.target.value);
            }}
            type="text"
            placeholder="Example: Lorazepam, Amoxicillin"
          />
          <datalist id="drugs" className={styles.relevantOptionsList}>
            {_.uniq(relevantOptions.map((x) => x.drugProductName)).map((drug, index) => (
              <option key={`drug-name-opt-${index}`} value={drug}></option>
            ))}
          </datalist>

          <button
            className={styles.searchButton}
            onClick={() => (searchValue.length ? history.push(`/results/${plan?.formularyTier}/${transformStringForURI(searchValue)}/${ResultsQueryType.ProductName}`) : setIsNotValid(true))}
          >
            <div>
              <SearchIcon />
            </div>
          </button>
        </div>
        {isNotValid ? <div className={`${styles.validationText} ${styles.blink}`}>Please enter the name of the drug you wish to search or browse the alphabetical index below.</div> : null}
      </div>
    </>
  );
};

const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, drugList: IDrug[], setRelevantOptions: React.Dispatch<React.SetStateAction<IDrug[]>>) => {
  const relevantDrugs = drugList.filter((x) => x.publicDrugName.toUpperCase().startsWith(event.target.value.toUpperCase()));
  setRelevantOptions(relevantDrugs.sort().slice(0, 10));
};

export default SearchBar;
