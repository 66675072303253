import styles from '../results/Results.module.scss';
import { ArrowLeft } from '../../icons/icons';
import ProfileDetailsTable from '../../components/ProfileDetailsTable/ProfileDetailsTable';
import PrintButton from '../../components/PrintButton/PrintButton';
import DownloadButton from '../../components/DownloadButton/DownloadButton';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { IAppContext } from '../../context/DefaultAppState';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { transformStringForURI } from '../../lib/util';

const ProfilePage = (props: any) => {
  const appContext = useContext(AppContext);
  const history = useHistory();
  const {tier, drugId} = props.match.params;
  useEffect(() => initializeProfile(appContext, tier, drugId), []);

  return (
    <>
      <div className={styles.topContainer}>
        <a onClick={() => history.goBack()} className={`${styles.navLink} ${styles.icon} ${styles.baseline}`}>
          <ArrowLeft />
          <span>Search Results</span>
        </a>
        <PrintButton />
        <DownloadButton />
      </div>
      <div>
        <ProfileDetailsTable />
      </div>
    </>
  );
};

const initializeProfile = (appContext: IAppContext, tier: number, drugId: number) => {
  (async () => {
    const formularyTier = appContext.plan?.formularyTier || tier;
    const { data } = await axios.get(`/Drugs/${formularyTier}/Profile/${transformStringForURI(drugId.toString())}`);
    appContext.updateDrugProfile(data);
  })();
};

export default ProfilePage;
