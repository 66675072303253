import { FC } from 'react';

const HeaderMenuExternal: FC = () => {
  return (
    <div className="showDesktop" style={{ height: '100%', paddingRight: '30px' }}>
      <ul>
        <li>
          <a href="https://simpra.com/shop-plans/" rel="noreferrer">
            Find a Plan
          </a>
        </li>
        <li>
          <a href="https://simpra.com/providers/" rel="noreferrer">
            For Providers
          </a>
        </li>
        <li>
          <a href="https://simpra.com/for-members/" rel="noreferrer">
            For Members
          </a>
        </li>
        <li>
          <a href="https://simpra.com/contact-us/" rel="noreferrer">
            Contact Us
          </a>
        </li>
        <li>
          <a href="mailto:brokersupport@simpra.com" rel="noreferrer">
            Brokers Get Certified
          </a>
        </li>
        <li style={{ display: 'flex', alignItems: 'center' }} className="find-service-container">
          <a href="https://ipd.simpra.com/" rel="noreferrer" className="find-service">
            Find a Provider/Pharmacy
          </a>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }} className="find-service-container">
          <a href="https://formulary.simpra.com/" rel="noreferrer" className="find-service">
            Find a Drug
          </a>
        </li>
      </ul>
    </div>
  );
};

export default HeaderMenuExternal;
