import { useContext, useState } from 'react';
import styles from './IndexLetterButtons.module.scss';
import { AppContext } from '../../context/AppContext';

const letters: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const IndexLetterButtons = (props: any) => {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const appContext = useContext(AppContext);

  return (
    <div className={styles.letterButtonContainer}>
      <p className={styles.letterButtonHeader}>Index by drug name</p>
      <div className="index-buttons">
        {letters.map((l: string, index: number) => (
          <button
            onClick={() => {
              setSelectedButtonIndex(index);
              appContext.updateSelectedLetter(l);
            }}
            key={index}
            className={`${styles.letterButton}${index === selectedButtonIndex ? ' ' + styles.selectedButton : ''}`}
          >
            {l.toUpperCase()}
          </button>
        ))}
      </div>
    </div>
  );
};

export default IndexLetterButtons;
