import { IDrug } from '../interfaces/IDrug';
import { IPlan } from '../interfaces/IPlan';

export interface IAppContext {
  selectedLetter: string;
  selectedControl: number;
  drugList: IDrug[];
  drugResultList: IDrug[];
  drugProfile: IDrug | null;
  therapeuticList: string[];
  therapeuticSubList: string[];
  cacheTime: string;
  filterTier: number;
  filterAuth: number;
  plansData: IPlan[];
  planUrl: string;
  plan: IPlan | null;
  planState: string;
  updateSelectedLetter: (x: string) => void;
  updateSelectedControl: (x: number) => void;
  updateDrugList: (x: IDrug[]) => void;
  updateDrugResultList: (x: IDrug[]) => void;
  updateDrugProfile: (x: IDrug) => void;
  updateTherapeuticList: (x: string[]) => void;
  updateTherapeuticSubList: (x: string[]) => void;
  updateCacheTime: (x: string) => void;
  updateFilterTier: (x: number) => void;
  updateFilterAuth: (x: number) => void;
  updatePlansData: (x: IPlan[]) => void;
  updatePlanUrl: (x: string) => void;
  updatePlan: (x: IPlan | null) => void;
  updatePlanState: (x: string) => void;
}

export const defaultAppState: IAppContext = {
  selectedLetter: 'A',
  selectedControl: 0,
  drugList: [],
  drugResultList: [],
  drugProfile: null,
  therapeuticList: [],
  therapeuticSubList: [],
  cacheTime: 'string',
  filterTier: 0,
  filterAuth: 0,
  plansData: [],
  planUrl: '',
  plan: null,
  planState: '',
  updateSelectedLetter: (x) => {},
  updateSelectedControl: (x) => {},
  updateDrugList: (x) => {},
  updateDrugResultList: (x) => {},
  updateDrugProfile: (x) => {},
  updateTherapeuticList: (x) => {},
  updateTherapeuticSubList: (x) => {},
  updateCacheTime: (x) => {},
  updateFilterTier: (x) => {},
  updateFilterAuth: (x) => {},
  updatePlansData: (x) => {},
  updatePlanUrl: (x) => {},
  updatePlan: (x) => {},
  updatePlanState: (x) => {}
};
