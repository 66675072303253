import styles from './ResultsTable.module.scss';
import ResultRow from './ResultRow/ResultRow';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';

const ResultsTable = () => {
  const { drugResultList, filterTier, filterAuth, updateFilterAuth, updateFilterTier, plan } = useContext(AppContext);
  useEffect(() => resetFilters(updateFilterAuth, updateFilterTier), []);
  const tier = plan?.formularyTier;

  let filteredDrugList = drugResultList;

  filteredDrugList = filterTier > 0 ? filteredDrugList.filter((x) => x.tierName.trim() === filterTier.toString()) : filteredDrugList;

  filteredDrugList = filterAuth > 0 ? filteredDrugList.filter((x) => x.priorAuthFlag === (filterAuth === 1 ? true : false)) : filteredDrugList;

  return (
    <>
      <div className={styles.resultsTableContainer}>
        {filteredDrugList.length ? (
          filteredDrugList.map((x, index) => {
            return <ResultRow key={`resRow-${x.drugProductId}-${index}`} tier={tier as number} drug={x} cssPosition={index + 1} />;
          })
        ) : (
          <div className={styles.noResults}>No Results</div>
        )}
      </div>
    </>
  );
};

const resetFilters = (updateFilterAuth: (x: number) => void, updateFilterTier: (x: number) => void) => {
  (async () => {
    updateFilterAuth(0);
    updateFilterTier(0);
  })();
};
export default ResultsTable;
