import { FC, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const HeaderMenuMobile: FC = () => {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <div className="header-menu-mobile-container showMobile">
      <button type="button" onClick={handleClick}>
        <MenuIcon />
      </button>
      {toggle && (
        <div className="mobile-nav-wrapper">
          <div className="toggle-container">
            <button type="button" onClick={handleClick} className="close">
              <CloseIcon />
            </button>
            <ul>
              <li>
                <a href="https://simpra.com/shop-plans/" rel="noreferrer">
                  Find a Plan
                </a>
              </li>
              <li>
                <a href="https://simpra.com/providers/" rel="noreferrer">
                  For Providers
                </a>
              </li>
              <li>
                <a href="https://simpra.com/for-members/" rel="noreferrer">
                  For Members
                </a>
              </li>
              <li>
                <a href="https://simpra.com/contact-us/" rel="noreferrer">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="mailto:brokersupport@simpra.com" rel="noreferrer">
                  Brokers Get Certified
                </a>
              </li>
              <li>
                <a href="https://ipd.simpra.com/" rel="noreferrer">
                  Find a Provider/Pharmacy
                </a>
              </li>
              <li>
                <a href="https://formulary.simpra.com/" rel="noreferrer">
                  Find a Drug
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMenuMobile;
