import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import styles from './ProfileDetailsTable.module.scss';

const ProfileDetailsTable = () => {
  const { drugProfile } = useContext(AppContext);

  return (
    <>
      {drugProfile ? (
        <div className={styles.tableContainer}>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Drug Name</div>
            <div className={`${styles.info}${drugProfile.publicFormatCd ? ' ' + styles.italics : ''}`}>{drugProfile?.publicDrugName}</div>
          </div>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Category</div>
            <div className={styles.infoSecondary}>{drugProfile.categoryName}</div>
          </div>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Class</div>
            <div className={styles.infoSecondary}>{drugProfile.className}</div>
          </div>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Quantity Limits</div>
            <div className={styles.info}>
              {drugProfile.qlPubRetailAmt1 ? `${drugProfile.qlPubRetailAmt1} ${drugProfile.qlPubRetailAmtUom1} / ${drugProfile.qlPubRetailTime1} ${drugProfile.qlPubRetailTimeUom1}` : 'None'}
            </div>
          </div>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Non-Extended Day Supply (NDS)</div>
            <div className={styles.info}>{drugProfile.nds ? 'Yes' : 'No'}</div>
          </div>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Prior Authorization</div>
            <div className={styles.info}>{drugProfile.priorAuthTypeName}</div>
          </div>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Step Therapy</div>
            <div className={styles.info}>{drugProfile.stepTherapyFlag ? 'Yes' : 'No'}</div>
          </div>
          <div className={`${styles.tableRow} ${styles.oddRow}`}>
            <div className={styles.label}>Specialty Pharmacy</div>
            <div className={styles.info}>{drugProfile.mandSpecialtyPharmacyFlag ? 'Yes' : 'No'}</div>
          </div>
        </div>
      ) : (
        <div className={styles.unidentifiedDrug}>Unidentified Drug: Profile Unavailable</div>
      )}
    </>
  );
};

export default ProfileDetailsTable;
