import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import styles from './PriorAuthFilterSelect.module.scss';

const PriorAuthFilterSelect = () => {
  const appContext = useContext(AppContext);

  return (
    <>
      <select className={styles.select} onChange={(e) => appContext.updateFilterAuth(parseInt(e.target.value))}>
        <option value={0}>Prior Authorization Filter</option>
        <option value={1}>Required</option>
        <option value={2}>Not Required</option>
      </select>
    </>
  );
};

export default PriorAuthFilterSelect;
