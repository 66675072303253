import styles from './DefaultButton.module.scss';

const DefaultButton = (props: any) => {
  return (
    <button disabled={props.shouldDisable} className={`${styles.defaultButton}${props.selectedText === props.buttonText ? ' ' + styles.selected : ''}${props.shouldDisable ? ' ' + styles.disabled : ''}`} onClick={() => props.customClick()}>
      <span>{props.buttonText}</span>
    </button>
  );
};

export default DefaultButton;
