import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { IAppContext } from '../../context/DefaultAppState';
import { ArrowLeft } from '../../icons/icons';
import { decodeCustomUriString } from '../../lib/util';
import { ResultsQueryType } from '../../pages/results';
import PrintButton from '../PrintButton/PrintButton';
import DownloadButton from '../DownloadButton/DownloadButton';
import styles from './TherapeuticSubClassList.module.scss';

const TherapeuticSubClassList = (props: any) => {
  const appContext = useContext(AppContext);
  const { tier, therapeuticCategory } = props.match.params;
  const category = decodeCustomUriString(therapeuticCategory);
  const history = useHistory();
  useEffect(() => initializeSubClassList(appContext, tier, therapeuticCategory, history), []);

  return (
    <>
      <div className={styles.topContainer}>
        <Link to={'/'} className={`${styles.icon} ${styles.baseline}`}>
          <ArrowLeft />
          <span>Prescription Drug List</span>
        </Link>
        <PrintButton />
        <DownloadButton />
      </div>
      <h2 className={styles.letterTitle}>{category}</h2>
      <hr />
      <div className="index-list">
        {appContext.therapeuticSubList.map((x, i) => (
          <div className={styles.listItem}>
            <Link key={`tscl-${x}-${i}`} to={`/results/${tier}/${x}/${ResultsQueryType.Classification}`}>
              {x}
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

const initializeSubClassList = (appContext: IAppContext, tier: number, therapeuticCategory: string, history: any) => {
  (async () => {
    const formularyTier = appContext.plan?.formularyTier || tier;
    const { data } = await axios.get(`/Drugs/${formularyTier}/Category/Class/${therapeuticCategory}`);
    if (!data.length) {
      history.push(`/results/${formularyTier}/${therapeuticCategory}/${ResultsQueryType.Category}`);
    }
    appContext.updateTherapeuticSubList(data);
  })();
};

export default TherapeuticSubClassList;
